<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 172 172"
    style="fill: inherit;"
  >
    <g
      fill-rule="nonzero"
      stroke="none"
      stroke-width="1"
      stroke-linecap="butt"
      stroke-linejoin="miter"
      stroke-miterlimit="10"
      stroke-dasharray=""
      stroke-dashoffset="0"
      font-family="none"
      font-weight="none"
      font-size="none"
      text-anchor="none"
      style="mix-blend-mode: normal"
    >
      <path d="M0,172v-172h172v172z" fill="none"></path>
      <g>
        <path
          d="M86,159.71429c-40.64729,0 -73.71429,-33.067 -73.71429,-73.71429c0,-40.64729 33.067,-73.71429 73.71429,-73.71429c40.64729,0 73.71429,33.067 73.71429,73.71429c0,40.64729 -33.067,73.71429 -73.71429,73.71429zM86,18.42857c-37.25643,0 -67.57143,30.315 -67.57143,67.57143c0,37.25643 30.315,67.57143 67.57143,67.57143c37.25643,0 67.57143,-30.315 67.57143,-67.57143c0,-37.25643 -30.315,-67.57143 -67.57143,-67.57143z"
          fill="inherit"
        ></path>
        <path
          d="M130.61557,101.35714c-9.26957,0 -16.80686,-7.57414 -16.80686,-16.89286c0,-9.31871 7.53729,-16.89286 16.80686,-16.89286c9.26957,0 16.813,7.57414 16.813,16.89286c0,9.31871 -7.54343,16.89286 -16.813,16.89286zM130.61557,73.71429c-5.87871,0 -10.664,4.82214 -10.664,10.75c0,5.92786 4.78529,10.75 10.664,10.75c5.88486,0 10.67014,-4.82214 10.67014,-10.75c0,-5.92786 -4.78529,-10.75 -10.67014,-10.75z"
          fill="inherit"
        ></path>
        <path
          d="M27.64286,101.35714c-0.78629,0 -1.56643,-0.301 -2.16843,-0.89686c-1.204,-1.19171 -1.204,-3.139 -0.01229,-4.343l27.48314,-27.64286c1.19171,-1.204 3.139,-1.204 4.343,-0.01229c1.204,1.19171 1.204,3.139 0.01229,4.343l-27.48314,27.64286c-0.59586,0.60814 -1.38214,0.90914 -2.17457,0.90914z"
          fill="inherit"
        ></path>
        <path
          d="M55.126,101.35714c-0.79243,0 -1.57871,-0.301 -2.17457,-0.903l-27.48314,-27.64286c-1.19171,-1.204 -1.19171,-3.15129 0.01229,-4.343c1.19786,-1.19171 3.14514,-1.19171 4.343,0.01229l27.48314,27.64286c1.19171,1.204 1.19171,3.15129 -0.01229,4.343c-0.602,0.58971 -1.38214,0.89071 -2.16843,0.89071z"
          fill="inherit"
        ></path>
        <path
          d="M74.68486,101.35714c-9.27571,0 -16.813,-7.57414 -16.813,-16.89286c0,-9.31871 7.53729,-16.89286 16.813,-16.89286c9.26957,0 16.80686,7.57414 16.80686,16.89286c0,1.69543 -1.376,3.07143 -3.07143,3.07143h-23.96329c1.31457,4.43514 5.40571,7.67857 10.22786,7.67857c2.64757,0 6.51757,-0.50986 9.53371,-4.95114c0.95829,-1.40671 2.881,-1.75071 4.26314,-0.81086c1.40671,0.946 1.763,2.86257 0.81086,4.26314c-3.44,5.074 -8.35429,7.64171 -14.60771,7.64171zM64.457,81.39286h20.44957c-1.31457,-4.43514 -5.39957,-7.67857 -10.22171,-7.67857c-4.82214,0 -8.91329,3.24343 -10.22786,7.67857z"
          fill="inherit"
        ></path>
        <path
          d="M101.437,101.35714c-1.69543,0 -3.07143,-1.376 -3.07143,-3.07143v-27.64286c0,-1.69543 1.376,-3.07143 3.07143,-3.07143c1.69543,0 3.07143,1.376 3.07143,3.07143v27.64286c0,1.69543 -1.376,3.07143 -3.07143,3.07143z"
          fill="inherit"
        ></path>
        <path
          d="M101.437,82.00714c-1.69543,0 -3.07143,-1.376 -3.07143,-3.07143c0,-5.74971 5.504,-11.18 11.32129,-11.18c1.69543,0 3.07143,1.376 3.07143,3.07143c0,1.69543 -1.376,3.07143 -3.07143,3.07143c-2.61686,0 -5.17843,2.88714 -5.17843,5.03714c0,1.69543 -1.376,3.07143 -3.07143,3.07143z"
          fill="inherit"
        ></path>
        <path
          d="M130.61557,90.3c-3.20657,0 -5.81729,-2.61686 -5.81729,-5.83571c0,-3.21886 2.61071,-5.83571 5.81729,-5.83571c3.20657,0 5.82343,2.61686 5.82343,5.83571c0,3.21886 -2.61686,5.83571 -5.82343,5.83571zM130.29614,84.46429c0,0.172 0.13514,0.30714 0.31943,0.30714z"
          fill="inherit"
        ></path>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
};
</script>
